var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({ 'z-index': 8,height:'300px',overflow:'scroll'}),attrs:{"onClick":_vm.callParent}},[_c('v-rect',{style:({ zIndex: 10 }),attrs:{"config":{
            x: _vm.positionsOfNodes[0].x,
            y: _vm.positionsOfNodes[0].y - 30,
            width: _vm.titleText.length > 47 ? _vm.titleText.length*7 : 300,
            height: 30,
            stroke: '#fff',
            fill: _vm.type === 'dimension' || _vm.type==='Dimension' ? '#B2AE36' : '#c76e1f'
        }},on:{"click":_vm.callParent}}),_c('v-text',{style:({ zIndex: 10 }),attrs:{"config":{
            text: _vm.titleText,
            x: _vm.positionsOfNodes[0].x,
            y: _vm.positionsOfNodes[0].y - 30,
            padding: 10,
            fill: '#fff'
        }},on:{"click":_vm.callParent}}),_c('v-image',{attrs:{"config":{
            x: _vm.positionsOfNodes[0].x + (this.titleText.length > 47 ? this.titleText.length*7 -20 : 280),
            y: _vm.positionsOfNodes[0].y - 20,
            image: this.showFull ? this.caretUpImg : this.caretDownImg,
            height: 10,
            width: 10
        }},on:{"click":_vm.setShowFull}}),_vm._l((_vm.nonDuplicateItems),function(generalItem,index){return _c('div',{key:index * 2},[(!generalItem.targetObjectAttributeName)?_c('div',[_c('v-rect',{style:({ zIndex: 10 }),attrs:{"config":{
                      x: _vm.positionsOfNodes[index].x,
                    y: _vm.positionsOfNodes[index].y,
                    width: _vm.positionsOfNodes[index].width,
                   height:30,
                    stroke: '#fff',
                    fill: '#F0F0F0'
                }}}),(_vm.type !== 'fact')?_c('div',_vm._l((_vm.getAllColorBlocks(generalItem, _vm.type)),function(item,indexOfItem){return _c('div',{key:indexOfItem * 3 + 'color'},[_c('v-rect',{style:({ zIndex: 10 }),attrs:{"config":{
                        x:
                            _vm.positionsOfNodes[index].x +
                            (indexOfItem === 0 ? 0 : indexOfItem * 10),
                        y: _vm.positionsOfNodes[index].y + 10,
                        width: 10,
                        height: 10,
                        // stroke: '#fff',
                        fill: item?.colorCode,
                        padding: 3
                    }}})],1)}),0):_vm._e(),_c('v-text',{staticClass:"odd",style:({ zIndex: 10 }),attrs:{"config":{
                    x: _vm.positionsOfNodes[index].x + 30,
                    y: _vm.positionsOfNodes[index].y,
                    width: 300,
                 height:30,
                    wrap:'word',
                    text:
                        _vm.type === 'dimension'
                            ? generalItem.primaryAttributeName ||
                              generalItem.targetObjectAttributeName
                            : generalItem.foreignAttributeName ||
                              generalItem.targetObjectAttributeName,

                    fill: '#000',
                    padding: 10
                }}})],1):_vm._e(),(_vm.showFull && generalItem.targetObjectAttributeName)?_c('div',[_c('v-rect',{style:({ zIndex: 10 }),attrs:{"config":{
                         x: _vm.positionsOfNodes[index].x,
                    y: _vm.positionsOfNodes[index].y,
                    width: _vm.titleText.length > 47 ? _vm.titleText.length*7 : 300,
                   height:30,
                    stroke: '#fff',
                    padding:20,
                    fill: '#F0F0F0'
                }}}),_c('v-text',{staticClass:"odd",style:({ zIndex: 10 }),attrs:{"config":{
                    x: _vm.positionsOfNodes[index].x - 5,
                    y: generalItem.targetObjectAttributeName.length>46?_vm.positionsOfNodes[index].y-10:_vm.positionsOfNodes[index].y,
                    width: _vm.titleText.length > 47 ? _vm.titleText.length*7 : 300 ,
                    height:generalItem.targetObjectAttributeName.length>46?45:30,
                    wrap:'word',
                    // ellipsis:true,
                    text: generalItem.targetObjectAttributeName,

                    fill: '#000',
                    padding: 10
                }}})],1):_vm._e(),(_vm.type === 'fact' && _vm.getAllColorBlocks(generalItem, 'fact').length <= 3)?_c('div',_vm._l((_vm.getAllColorBlocks(generalItem, 'fact')),function(item,indexOfItem){return _c('div',{key:indexOfItem * 3 + 'color'},[_c('v-rect',{style:({ zIndex: 10 }),attrs:{"config":{
                    x: _vm.positionsOfNodes[index].x + (indexOfItem === 0 ? 3 : indexOfItem * 10 + 3),
                    y: _vm.positionsOfNodes[index].y + 10,
                    width: 8,
                    height: 8,
                    // stroke: '#fff',
                    fill: item?.colorCode,
                    padding: 5
                }}})],1)}),0):_vm._e(),(_vm.type === 'fact' && _vm.getAllColorBlocks(generalItem, 'fact').length > 3)?_c('v-image',{attrs:{"config":{
                x: _vm.positionsOfNodes[index].x+13,
                y: _vm.positionsOfNodes[index].y + 10,
            
                ..._vm.imageConfig
            }}}):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }