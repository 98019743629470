export class TargetObject {
    targetObjectId: number;
    targetObjectName: string;
    acceptanceCriteria: string;
    businessDataOwnerName: string;
    complexityEstimate: string;
    dataArchivalTypeId: number;
    dataClassification: string;
    dataEncryption: string;
    dataHistoryBau: string;
    dataHistoryInitial: string;
    dataOwnerId: number;
    dataRefreshFrequency: string;
    dataSetId: number;
    description: string;
    detailedInstructions: string;
    displayName: string;
    dmrtranslatorNotesTob: string;
    dmrscheduleTime: string;
    dmrstatus: string;
    objectHardDependency: string;
    objectSoftDependency: string;
    objectDqrequirement: string;
    jiraReferenceId: string;
    personalIdentifiableInformationId: number;
    platformId: number;
    previousDataOwnerId: number;
    regionalScaleout: string;
    scheduleDependencies: string;
    scheduleFailAction: string;
    smenotesTob: string;
    sourceJoinConditions: string;
    targetObjectTypeId: number;
    techDeletePattern: string;
    techDeletePatternId: number;
    techDeleteDependencies: string;
    tofsreviewRename: string;
    tofsreviewCurName: string;
    uatfilterAttribute: string;
    updateType: string;
    loadDate: string;
    refreshDate: string;
    pkcheck: boolean;
    jobCompletionCheck: boolean;
    slacheck: boolean;
    isEditable: boolean;
    auditIsocountryKey: number;
    geoCoverageLevelId: number;
    geoCoverageValueCode: string;
    businessGroupCoverageCode: string;
    subFunctionCode: string;
    abstractionViewDetails: string;
    dataClassificationDetailId: number;
    regionSegregatedYn: boolean;
    templateTobid: number;

}
