var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",style:({overflowY: 'scroll',overflowX: 'scroll',height: _vm.windowHeight})},[_c('TitleComponentForER',{attrs:{"showButtons":_vm.allPromisesDone,"breadcrumbItems":_vm.breadcrumbItems},on:{"zoom-in-zoom-out":_vm.zoomInZoomOutOnClick,"download-image-as-pdf":_vm.downloadImageAsPdf,"download-as-png":_vm.downloadImageAsPng}}),(!_vm.allPromisesDone)?_c('div',{staticClass:"mt-9"},[_c('SkeletonLoaderTable')],1):_vm._e(),(_vm.allPromisesDone)?_c('div',[(_vm.getMetadata.length <= 0 && _vm.floatingTablesData?.length<=0)?_c('v-container',{attrs:{"fill-height":""}},[_c('NoData')],1):_vm._e()],1):_vm._e(),(_vm.allPromisesDone)?_c('v-stage',{ref:"titleRef",attrs:{"config":{height:150,width:500}}},[_c('v-layer',[(_vm.getMetadata.length>0)?_c('v-text',{attrs:{"config":{
                                text: _vm.getMetadata[0]?.productName ,
                                x: 10,
                                y: 10,
                                padding: 10,
                                fill: 'blue',
                                fontSize:21
                            }}}):_vm._e(),(_vm.getMetadata.length>0)?_c('v-text',{attrs:{"config":{text: `LAST UPDATED: ${_vm.getLatestUpdatedDate()} - GMT`, x: 10,
                                y: 45,
                                padding: 10,
                                fill: 'black',
                                fontSize:15}}}):_vm._e(),(_vm.getMetadata.length>0 || _vm.floatingTablesData.length>0)?_c('v-rect',{attrs:{"config":_vm.buttonConfig},on:{"mouseenter":_vm.showPointerEnter,"mouseleave":_vm.showPointerLeave,"click":_vm.showHideFloatingTables}}):_vm._e(),(_vm.getMetadata.length>0 || _vm.floatingTablesData.length>0)?_c('v-text',{attrs:{"config":{x:40,y:93,text:`FLOATING OBJECTS: ${_vm.floatingTablesData.length}`, fontSize:14,fontFamily:'Roboto, sans-serif'}},on:{"mouseenter":_vm.showPointerEnter,"mouseleave":_vm.showPointerLeave,"click":_vm.showHideFloatingTables}}):_vm._e()],1)],1):_vm._e(),(_vm.showFloating)?_c('div',{ref:"containertwo"},[_c('div',{style:({ display: 'flex', flexDirection: 'row' })},[_c('div',[_c('v-stage',{ref:"stageRefForFloating",attrs:{"config":_vm.configForStageOfFloating}},[_c('v-layer',[_c('v-group',_vm._l((_vm.floatingTablesData),function(item,floatingIndex){return _c('div',{key:'floatingTablesUniqueKey'+floatingIndex},[_c('FactOrDimensionTable',{key:'floating' + floatingIndex * 1,attrs:{"title-text":item.targetObjectName,"isItAFloating":true,"data":item,"position":_vm.getPosition({ type: item.targetObjectTypeName, item: item.targetObjectName+item.targetObjectId, x: 50,isItFloating:true }),"type":item.targetObjectTypeName}})],1)}),0)],1)],1)],1),_c('div',[_c('v-stage',{ref:"stageRef",attrs:{"config":_vm.configKonva}},[(_vm.hasBeenMounted)?_c('v-layer',[(_vm.arrowsShouldAppear)?_c('v-group',[_c('div',_vm._l((_vm.getMetadata),function(item,indexForMetadata){return _c('div',{key:indexForMetadata},[_c('div',{key:'arrowdiv' + indexForMetadata},[_c('v-arrow',{key:'arrowKey' + item.primaryAttributeName+item.primaryObjectID+item.foreignObjectName,ref:'arrow' + item.primaryAttributeName+item.primaryObjectID+item.foreignObjectName,refInFor:true,attrs:{"config":_vm.uniqueHashMapForArrows[item.primaryObjectName + item.foreignObjectName + item.colorCode]},on:{"click":($event) =>
                                                      _vm.showHighlightedEnter(
                                                          $event,
                                                          item,
                                                          '#0533cc',
                                                          '#0533cc',
                                                          item.colorCode
                                                      )}}),_c('v-circle',{key:'circle'+indexForMetadata,attrs:{"config":_vm.getPositionForCircle(item)}})],1)])}),0)]):_vm._e(),_c('v-group',[(_vm.arrowsOfHighlightToAppear)?_c('div',_vm._l((_vm.highlightArrowsItems),function(item,indexForArrows){return _c('div',{key:indexForArrows},[_c('div',{key:'arrowdiv' + indexForArrows},[_c('v-arrow',{key:'arrowKey' + indexForArrows,ref:'arrow' + indexForArrows,refInFor:true,attrs:{"config":item},on:{"click":($event) =>
                                                      _vm.showHighlightedEnter(
                                                          $event,
                                                          item,
                                                          '#0533cc',
                                                          '#0533cc',
                                                          item.colorCode
                                                      )}})],1)])}),0):_vm._e()])],1):_vm._e(),(_vm.hasBeenMounted )?_c('v-layer',[_c('div'),_c('v-group',[_c('div',{style:({overflowY:'scroll',overflowX:'scroll'})},[_vm._l((Object.keys(
                                      _vm.getFullDataList['dimensionTableGroups']
                                  )),function(item,index){return _c('div',{key:'dim' + index},[_c('FactOrDimensionTable',{key:'dim' + index * 1,attrs:{"isItAFloating":false,"title-text":item,"data":_vm.getFullDataList['dimensionTableGroups'][item],"position":_vm.getPosition({ type: 'dimension', item: item, x: 50 }),"type":"dimension"}})],1)}),(_vm.showHighlight)?_c('div',_vm._l((_vm.positionOfHighlightElements),function(positionItem,positionItemIndex){return _c('div',{key:positionItemIndex},[_c('v-rect',{key:positionItem?.xPositionOfSource + positionItemIndex,attrs:{"config":{
                                              x: positionItem?.xPositionOfSource,
                                              y: positionItem?.yPositionOfSource,
                                              width: positionItem?.width,
                                              height: 30,
                                              stroke: 'black',
                                              fill: _vm.black,
          
                                              padding: 3,
                                              zIndex: 90
                                          }}})],1)}),0):_vm._e(),_vm._l((Object.keys(
                                      _vm.getFullDataList['factTableGroups']
                                  )),function(factItem,factIndex){return _c('div',{key:'fact' + factIndex},[_c('FactOrDimensionTable',{key:'fact' + factIndex * 1,attrs:{"isItAFloating":false,"title-text":factItem,"data":_vm.getFullDataList['factTableGroups'][factItem],"position":_vm.getPosition({ type: 'fact', item: factItem, x: 50 }),"type":"fact"},on:{"show-fact-relations":_vm.showFactRelations}})],1)})],2)])],1):_vm._e()],1)],1)])]):_c('div',[_c('v-stage',{ref:"stageRef",attrs:{"config":_vm.configKonva}},[(_vm.hasBeenMounted)?_c('v-layer',[_c('v-group',[_c('v-rect',{style:({ zIndex: 0 }),attrs:{"config":{
                    x: 0,
               y:0,
               width: _vm.configKonva.width,
              height:1500,
               stroke: '#fff',
               fill: '#fff'
           }}})],1),(!_vm.showFloating && _vm.arrowsShouldAppear)?_c('v-group',[_c('div',_vm._l((_vm.getMetadata),function(item,indexForMetadata){return _c('div',{key:indexForMetadata},[_c('div',{key:'arrowdiv' + indexForMetadata},[_c('v-arrow',{key:'arrowKey' + item.primaryAttributeName+item.primaryObjectID+item.foreignObjectName,ref:'arrow' + item.primaryAttributeName+item.primaryObjectID+item.foreignObjectName,refInFor:true,attrs:{"config":_vm.uniqueHashMapForArrows[item.primaryObjectName + item.foreignObjectName + item.colorCode]},on:{"click":($event) =>
                                          _vm.showHighlightedEnter(
                                              $event,
                                              item,
                                              '#0533cc',
                                              '#0533cc',
                                              item.colorCode
                                          )}}),_c('v-circle',{key:'circle'+indexForMetadata,attrs:{"config":_vm.getPositionForCircle(item)}})],1)])}),0)]):_vm._e(),_c('v-group',[(_vm.arrowsOfHighlightToAppear)?_c('div',_vm._l((_vm.highlightArrowsItems),function(item,indexForArrows){return _c('div',{key:indexForArrows},[_c('div',{key:'arrowdiv' + indexForArrows},[_c('v-arrow',{key:'arrowKey' + indexForArrows,ref:'arrow' + indexForArrows,refInFor:true,attrs:{"config":item},on:{"click":($event) =>
                                          _vm.showHighlightedEnter(
                                              $event,
                                              item,
                                              '#0533cc',
                                              '#0533cc',
                                              item.colorCode
                                          )}})],1)])}),0):_vm._e()])],1):_vm._e(),(_vm.hasBeenMounted )?_c('v-layer',[_c('v-group',[_c('div',{style:({overflowY:'scroll',overflowX:'scroll'})},[_vm._l((Object.keys(
                          _vm.getFullDataList['dimensionTableGroups']
                      )),function(item,index){return _c('div',{key:'dim' + index},[_c('FactOrDimensionTable',{key:'dim' + index * 1,attrs:{"isItAFloating":false,"title-text":item,"data":_vm.getFullDataList['dimensionTableGroups'][item],"position":_vm.getPosition({ type: 'dimension', item: item, x: 50 }),"type":"dimension"}})],1)}),(_vm.showHighlight)?_c('div',_vm._l((_vm.positionOfHighlightElements),function(positionItem,positionItemIndex){return _c('div',{key:positionItemIndex},[_c('v-rect',{key:positionItem?.xPositionOfSource + positionItemIndex,attrs:{"config":{
                                  x: positionItem?.xPositionOfSource,
                                  y: positionItem?.yPositionOfSource,
                                  width: positionItem?.width,
                                  height: 30,
                                  stroke: 'black',
                                  fill: _vm.black,

                                  padding: 3,
                                  zIndex: 90
                              }}})],1)}),0):_vm._e(),_vm._l((Object.keys(
                          _vm.getFullDataList['factTableGroups']
                      )),function(factItem,factIndex){return _c('div',{key:'fact' + factIndex},[_c('div',{style:({zIndex:81})},[_c('v-circle',{attrs:{"config":_vm.uniqueHashMapForCircles[_vm.getFullDataList['factTableGroups'][factItem].primaryObjectName + _vm.getFullDataList['factTableGroups'][factItem].foreignObjectName + _vm.getFullDataList['factTableGroups'][factItem].colorCode]}})],1),_c('FactOrDimensionTable',{key:'fact' + factIndex * 1,attrs:{"isItAFloating":false,"title-text":factItem,"data":_vm.getFullDataList['factTableGroups'][factItem],"position":_vm.getPosition({ type: 'fact', item: factItem, x: 50 }),"type":"fact"},on:{"show-fact-relations":_vm.showFactRelations}})],1)})],2)])],1):_vm._e()],1)],1),(_vm.allPromisesDone)?_c('div',[_c('DiagramCommentsBox',{attrs:{"productId":_vm.productId,"url":_vm.url}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }